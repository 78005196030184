@import "../../../assets/Styles/theme.scss";
.modal-backdrop{
    width: 100% !important;
    height: 100% !important;
}
.profile-status-wrapper {
    // position: fixed;
    // left: 0;
    // top: 0;
    // width: 100%;
    // height: 100%;
    padding: 0 20px;
    // // background-color: rgba(0,0,0,0.4);
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // z-index: 99999;
    .modal-content {
        border-radius: 15px;
        .profile-status {
            padding: 4px;
            background-color: #ffffff;
            border-radius: $border-radius;
            .heading-wrapper {
                text-align: center;
                .heading {
                    font-size: 20px;
                    font-weight: 400;
                    color: $dark-grey;
                }
                .sub-heading {
                    font-size: 12px;
                    color: $dark-grey;
                    margin-top: 5px;
                }
            }
            .body-content-wrapper {
                margin-top: 10px;
                .body-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    // margin-top: 20px;
                    padding-top: 20px;
                    .details {
                        font-size: 16px;
                        color: $dark-grey;
                        font-weight: 400;
                    }
                    .status-wrapper {
                        display: flex;
                        align-items: center;
                        .status {
                            font-size: 12px;
                            color: $dark-grey;
                            margin-right: 4px;
                            &.verified {
                                font-weight: 500;
                                color: #00a79d;
                            }
                        }
                    }
                }
            }
        }
    }
}
