@import "../assets/Styles/placeholder.scss";

// universal classes for auth


// .not-valid {
//     border: 1px solid #f97171 !important;
// }

// .error-msg {
//     display: block;
//     font-size: 14px;
//     color: #f97171;
//     margin-top: 15px;
//     @media (max-width: 767px) {
//         font-size: 10px;
//         margin-top: 10px;
//     }
// }
.phn-field{
    padding-left: 70px !important;
    @media (max-width: 767px) {
        padding-left: 100px !important;
    }
}
.phn-details-placeholder{
    position: absolute;
    left: 20px;
    top: 29px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
        left: 24px;
        top: 23px
    }
    p{
        display: none;
        margin-left: 10px;
        font-size: 16px;
        color: $light-grey;
        @media (max-width: 767px) {
            display: block;
            font-size: 12px;
            margin-left: 5px;
        }
    }
}

.gender-icon-placeholder{
    display: none;
    position: absolute;
    top: 6px;
    right: 18px;
    @media (max-width: 767px) {
        display: block;
    }
}
// auth styling
.auth-wrapper {
    @extend %full-centered;
    height: 127vh;
    @media (max-width: 991px) {
        padding: 0 20px;
    }
    @media (max-width: 767px) {
        padding: 0 12px;
        // padding-top: 230px;
    }
    .auth-inner-wrapper {
        max-width: 800px;
        width: 100%;
        padding: 60px 150px 50px 150px;
        border-radius: 20px;
        background-color: #ffffff;
        @media (max-width: 991px) {
            max-width: 600px;
            padding: 40px;
        }
        @media (max-width: 767px) {
            // max-width: 351px;
            background-color: transparent;
            padding: 48px 0px;
        }
        .auth-header {
            @media (max-width: 767px) {
                // background-color: $secondary;
                // border-radius: 0;
                // position: absolute;
                // top: 48px;
                // left: 0;
            }
            .logo-wrapper{
                text-align: center;
                @media (max-width: 767px) {
                    margin-left: 13px;
                    // margin-right: auto;
                    text-align: left;
                }
                // .logo-icon {
                //     width: 100%;
                // }
            }
            .auth-heading {
                font-size: 30px;
                font-weight: 500;
                padding: 0 50px;
                margin-top: 45px;
                // margin-bottom: 0;
                @media (max-width: 767px) {
                    font-size: 24px;
                    font-weight: 700;
                    margin-top: 63px;
                }
                @media (max-width: 350px) {
                    padding: 0 30px;
                }
            }
            .auth-sub-heading-1 {
                font-size: 18px;
                color: $light-grey;
                margin-top: 40px;
                padding: 0 55px;
                @media (max-width: 767px) {
                    font-size: 12px;
                    margin-top: 37px;
                }
                @media (max-width: 350px) {
                    padding: 0 40px;
                }
            }
            .auth-sub-heading-2 {
                font-size: 20px;
                font-weight: 500;
                color: $light-grey;
                margin-top: 15px;
                @media (max-width: 767px) {
                    font-size: 18px;
                    margin-top: 4px;
                    font-weight: normal;
                }
            }
            .sub-heading-modify{
                margin-top: 10px;
                padding-bottom: 10px;
                @media (max-width: 767px) {
                    font-size: 10px;
                    margin-top: 5px;
                    padding-bottom: 65px;
                }
            }
        }
        .form-wrapper {
            margin-top: 40px;
            @media (max-width: 767px) {
                margin-top:26px;
                background-color: white;
                padding: 40px 28px;
                border-radius: $border-radius;
            } 
            .input-wrapper {
                position: relative;
                margin-bottom: 40px;
                @media (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 20px;
                }
                // .input-box {
                //     border-radius: $border-radius;
                //     border: 1px solid $light-grey;
                //     padding: 11.5px 20px;
                //     font-size: 16px;
                //     font-weight: normal;
                //     outline: none;
                //     box-shadow: none;
                //     &::placeholder {
                //         color: $light-grey;
                //     }
                //     &:focus {
                //         border: 1px solid #06bbb7;
                //     }
                //     @media (max-width: 767px) {
                //         font-size: 12px;
                //         padding: 10px 24px;
                //     }
                // }
            }
            .inputName {
                display: flex;
                flex-wrap: wrap;
                align-self: center;
                justify-content: space-between;
            }
            .auth-navigation {
                font-size: 16px;
                font-weight: normal;
                color: $dark-grey;
                margin-top: 50px;
                @media (max-width: 767px) {
                    font-size: 10px;
                    margin-top: 10px;
                }
            }
            .otp-text {
                text-align: center;
                color: $dark-grey;
                font-size: 16px;
                margin-top: -25px;
                margin-bottom: 10px;
                @media (max-width: 767px) {
                    margin-top: -5px;
                    margin-bottom: 5px;
                    font-size: 9px;
                }
            }
            .otp-resend-link {
                font-size: 18px;
                margin-bottom: 35px;
                text-align: center;
                @media (max-width: 767px) {
                    font-size: 9px;
                    margin-bottom: 17px;
                }
            }
        }
    }
}
