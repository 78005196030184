@import "../assets/Styles/theme.scss";

.header-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 20px;
    @media (max-width: 767px) {
        margin: 20px 0;
    }
    .menu-icon-wrapper {
        display: none;
        
        @media (max-width: 767px) {
            display: block;
        }
    }
    
    .header-user-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: auto;
        margin-right: 30px;
        @media (max-width: 767px) {
            margin-right: 0px;
        }
        .header-icon {
            width: 50px;
            height: 50px;
            border: 1px solid $primary;
            background-color: #ebf2fc;
            border-radius: 50%;
           @media(max-width: 1368px){
                width: 40px;
                height: 40px;
           }
            @media (max-width: 767px) {
                width: 35px;
                height: 35px;
            }
        }
        .user-name-wrapper{
            margin-left: 10px;
            margin-top: 5px;
            .user-name{
                font-size: 20px;
                font-weight: 400;
                // margin-bottom: 5px;
                @media (max-width: 1368px) {
                    font-size: 18px;
                }
                @media (max-width: 767px) {
                    font-size: 16px;
                }
            }
            .user-role{
                font-size: 14px;
                color: $light-grey;
                @media (max-width: 1368px) {
                    font-size: 12px;
                }
            }
        }
    }
}
