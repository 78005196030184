@import "../../assets/Styles/theme.scss";
// common class
.msg-wrapper {
    display: flex;
    align-items: center;
    margin-top: 15px;
    @media (max-width: 767px) {
        margin-left: 24px;
        margin-top: 10px;
        align-items: unset;
    }
    @media (max-width: 359px) {
        margin-left: 10px;
    }
    .msg-icon {
        width: 16px;
        height: 16px;
        @media (max-width: 767px) {
            width: 12px;
            height: 12px;
        }
    }
    .msg-content {
        font-size: 16px;
        font-weight: 400;
        color: $dark-grey;
        margin-left: 12px;
        @media (max-width: 1024px) {
            font-size: 12px;
        }
        @media (max-width: 767px) {
            font-size: 10px;
            margin-left: 6px;
        }
    }
}
// request component class
.request-wrapper {
    background-color: #ffffff;
    border-radius: 15px;
    padding: 50px;
    @media (max-width: 1024px) {
        padding: 25px;
    }
    @media (max-width: 767px) {
        padding: 0;
        padding-top: 20px;
        background-color: transparent;
    }
    &.review {
        width: 600px;
        margin: 0 auto;
        @media (max-width: 1024px) {
            width: 100%;
        }
        @media (max-width: 767px) {
            background-color: #ffffff;
            padding: 25px 12px;
        }
    }
    .request-heading {
        text-align: center;
        font-size: 30px;
        font-weight: 500;
        color: $dark-grey;
        margin-bottom: 20px;
        @media (max-width: 1024px) {
            font-size: 24px;
            margin-bottom: 10px;
        }
        @media (max-width: 767px) {
            font-size: 20px;
        }
        @media (max-width: 359px) {
            font-size: 18px;
        }
    }
    .request-subheading {
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        color: $light-grey;
        margin-bottom: 40px;
        @media (max-width: 767px) {
            font-size: 10px;
            margin-bottom: 45px;
            color: $dark-grey;
        }
    }
    .form-wrapper {
        // width: 75%;
        margin: 0 auto;
        @media (max-width: 1024px) {
            width: 100%;
        }
        @media (max-width: 767px) {
            padding: 40px 25px;
            background-color: #ffffff;
            border-radius: 15px;
        }
        .input-wrapper {
            margin-bottom: 25px;
            @media (max-width: 767px) {
                margin-bottom: 17px;
            }
            input {
                width: 100%;
            }
        }
    }
}

// review request component
.request-amount-wrapper {
    padding: 30px;
    margin-top: 20px;
    border-radius: 15px;
    border: 1px solid $light-grey;
    @media (max-width: 767px) {
        padding: 15px 20px;
    }
    .request-amount {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .heading {
            color: $dark-grey;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 15px;
            @media (max-width: 1368px) {
                font-size: 14px;
            }
            @media (max-width: 767px) {
                font-size: 10px;
                margin-bottom: 5px;
            }
        }
        .value {
            color: $dark-grey;
            font-size: 18px;
            font-weight: 500;
            @media (max-width: 767px) {
                font-size: 14px;
            }
            @media (max-width: 359px) {
                font-size: 12px;
            }
        }
    }
    .total-info{
        font-size: 16px;
        margin-top: 30px;
        color: $dark-grey;
        @media (max-width: 767px) {
            font-size: 12px;
            margin-top: 25px;
        }
        @media (max-width: 359px) {
            font-size: 10px;
            margin-top: 20px;
        }
    }
}
    .btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        @media (max-width: 767px) {
            margin-top: 20px;
        }
        .btn,
        .btn-cancel {
            width: 135px;
            font-size: 16px;
            font-weight: 400;
            @media (max-width: 767px) {
                width: 100px;
                font-size: 14px;
                padding: 8px;
            }
            @media (max-width: 359px) {
                font-size: 12px;
            }
        }
        .btn-cancel {
            background-color: #ffffff;
            margin-right: 50px;
            color: $primary;
            border: 1px solid $primary;
            @media (max-width: 767px) {
                margin-right: 15px;
            }
        }
    }

    #handleClickDownload{
        display: none;
    }