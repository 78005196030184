@import "../assets/Styles/theme.scss";
.sidebar-outer-wrapper {
    position: fixed;
    z-index: 10;
    @media (max-width: 767px) {
        width: 100%;
        left: -105%;
        transition: all 0.2s linear;
        &.active {
            left: 0;
        }
        &.background-active {
            background-color: rgba(0, 0, 0, 0.2);
        }
    }
    .sidebar-inner-wrapper {
        display: block;
        overflow: auto;
        width: 346px;
        background-color: #ffffff;
        height: 130vh;
        padding: 0 15px;
        @media (max-width: 1368px) {
            width: 300px;
        }
        @media (max-width: 767px) {
            width: 70%;
            padding: 0 25px;
        }
        @media (max-width: 359px) {
            width: 100%;
        }
        .logo-wrapper {
            text-align: center;
            margin-top: 50px;
            i {
                display: none;
            }
            @media (max-width: 1368px) {
                width: 200px;
                .logo {
                    width: 100%;
                }
            }
            @media (max-width: 767px) {
                text-align: left;
                width: 138px;
                .logo {
                    width: 100%;
                }
            }
            @media (max-width: 359px) {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .logo {
                    width: 138px;
                }
                i {
                    display: block;
                }
            }
        }
        .menu-wrapper {
            margin-top: 100px;
            @media (max-width: 767px) {
                margin-top: 76px;
            }
            .menu-item-wrapper {
                // margin-bottom: 40px;
                &.log-out-item {
                    margin-top: 120px;
                    @media (max-width: 767px) {
                        // display: none;
                        margin-top: 60px;
                    }
                }
                .menu-item {
                    display: block;
                    // text-align: center;
                    width: 100%;
                    padding: 10px 25px;
                    margin-bottom: 20px;
                    color: $dark-grey;
                    font-size: 20px;
                    white-space: nowrap;
                    @media (max-width: 767px) {
                        padding: 10px 15px;
                        font-size: 18px;
                    }

                    &.active {
                        border-radius: $border-radius;
                        background-color: $primary;
                        color: #ffffff;
                    }
                }
            }
        }
    }
}
