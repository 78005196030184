@import "../../assets/Styles/theme.scss";

/*============= Dashboard card =============*/
.card-dashboard-wrapper {
    @media (min-width: 1400px) {
        // width: 24% !important;
        margin-right: auto;
    }

    .card-dashboard {
        position: relative;
        background-color: #ffffff;
        margin-bottom: 40px;
        padding: 25px 20px 25px 30px;
        border-radius: 5px;
        @media (max-width: 1600px) {
            padding: 25px 18px 25px 24px;
            margin-bottom: 20px;
        }
        .notification-badge {
            position: absolute;
            top: -15px;
            right: -15px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            color: #ffffff;
            background-color: #f97171;
            font-size: 22px;
            font-weight: 500;
            @media (max-width: 767px) {
                width: 28px;
                height: 28px;
                font-size: 18px;
            }
        }
        .dashboard-heading {
            font-size: 18px;
            margin-bottom: 5px;
            @media (max-width: 1600px) {
                font-size: 16px;
            }
            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
        .dashboard-count {
            font-size: 32px;
            font-weight: 500;
            margin-bottom: 5px;
            @media (max-width: 1600px) {
                font-size: 28px;
            }
            @media (max-width: 767px) {
                font-size: 24px;
            }
            .total {
                font-size: 18px;
                color: $light-grey;
                font-weight: normal;
                margin-left: 2px;
                @media (max-width: 767px) {
                    font-size: 14px;
                }
            }
        }
        .dashboard-status-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .dashboard-status {
                font-size: 16px;
                color: $light-grey;
                white-space: nowrap;
                @media (max-width: 1600px) {
                    font-size: 14px;
                }
                @media (min-width: 1200px) and (max-width: 1366px) {
                    font-size: 12px;
                }
                @media (max-width: 1199px) {
                    font-size: 14px;
                }
            }
            .refresh-icon {
                @media (max-width: 1600px) {
                    width: 16px;
                    height: 12px;
                }
            }
        }
    }
}
