@import "./../../assets/Styles/theme.scss";

.manage-admin {
    background-color: $white;
    padding: 50px;

    .data-table {
        table tr * {
            padding: unset;
        }

        table tr th {
            padding: 1rem;
        }

        table {
            .table-row {
                border-bottom: 2px solid rgba(3, 18, 30, 0.07) !important;

                &:last-child {
                    border: 0;
                }

                td {
                    border-right: 0 !important;
                    padding: 1rem;
                }
            }
        }

        overflow: hidden;
        border-radius: 15px;
        border: 1px solid #dedede;

        .status-column {
            background: rgba(3, 18, 30, 0.07);
            border-radius: 70px;
            max-width: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            padding: 7px;

            .icon {
                width: 7px;
                height: 7px;
                display: block;
                border-radius: 50%;
                background-color: black;
                margin-right: 10px;

                &.active {
                    background-color: #00A79D;
                }
            }
        }
    }
}

.admin-button-wrapper {
    display: flex;

    .btn-admin-add {
        max-width: 204px;
        background: rgba(37, 140, 242, 0.1);
        border-radius: 10px;
        color: #258CF2;
    }
}