@import "./theme.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&family=Rubik:wght@300;400;500;700&display=swap");

html {
    scroll-behavior: smooth;
  }
  
body {
    font-family: "Rubik", sans-serif;
    background-color: $secondary;
}
a {
    text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
label {
    margin: 0;
    padding: 0;
}
ul {
    list-style-type: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.top-heading{
    position: absolute;
    top: 35px;
    font-size: 30px;
}

/*==================== universal class ====================*/
.btn {
    font-size: 18px;
    font-weight: 500;
    width: 100%;
    border-radius: $border-radius;
    padding: 14px;
    margin-top: 10px;
    box-shadow: none !important;
    &.btn-width{
        min-width: 250px;
        @media(max-width: 767px){
            min-width: auto;
            padding: 10px 30px;
        }
    }
    &.primary-btn{
        background-color: $primary;
        color: #ffffff;
    }
    &.primary-black{
        background-color: #000000;
        color: #ffffff;
    }
    &.success-btn {
        background-color: #198754;
    }
    &.danger-btn {
        background-color: #dc3545;
    }
    &.white-btn{
        background-color: #ffffff;
        color: #000000;
    }
    &.bordered-btn{
        border: 1px solid #258CF2;
        color: #258CF2;
    }
    &.min-padding{
        padding: 15px 50px;
        width: auto !important;
    }
    @media (max-width: 767px) {
        font-size: 14px;
        font-weight: 400;
        padding: 10px 11px;
    }
}
.heighlight {
    color: $primary;
    font-weight: normal;
    cursor: pointer;
}
.text-wrapper {
    margin-top: 15px;
    font-size: 14px;
    @media (max-width: 767px) {
        font-size: 10px;
        margin-top: 10px;
    }
}

.export-csv{
    padding: 1rem;
    background-color: $primary;
    border-radius: 8px;
    color: $white;
    cursor: pointer;
    i{
        margin-right: 0.5rem;
    }
}

/* errors*/
.valid {
    border: 1px solid #06bbb7 !important;
}
.not-valid {
    border: 1px solid #f97171 !important;
}
.error-msg {
    display: block;
    font-size: 14px;
    color: #f97171;
    margin-top: 15px;
    @media (max-width: 767px) {
        font-size: 10px;
        margin-top: 10px;
    }
}
/*==================== input field related classes ====================*/
.input-wrapper {
    &.check-terms {
        font-size: 16px;
        color: $dark-grey;
        @media (max-width: 767px) {
            margin-top: -12px;
            font-size: 10px;
            padding-left: 24px;
        }
    }
    .input-box {
        border-radius: $border-radius;
        border: 1px solid $light-grey;
        padding: 15px 20px;
        font-size: 16px;
        font-weight: normal;
        outline: none;
        box-shadow: none;
        &::placeholder {
            color: $light-grey;
        }
        &:focus {
            border: 1px solid #06bbb7;
        }
        @media (max-width: 767px) {
            font-size: 12px;
            padding: 13px 24px 14px 24px;
        }
        @media (max-width: 359px) {
            padding: 10px 20px;
        }
    }
    .input-otp-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        .input-otp {
            width: 50px !important;
            height: 50px;
            margin-right: 16px;
            border: 1px solid $light-grey;
            border-radius: $border-radius;
            @media (max-width: 767px) {
                width: 45px !important;
                height: 45px;
            }
            .focus-style {
                border: 1px solid #06bbb7 !important;
            }
        }
    }
}

.input-icon {
    position: absolute;
    top: 20px;
    right: 24px;
    @media (max-width: 767px) {
        top: 15px;
    }
}

/*==================== react date picker ====================*/

.react-datepicker-popper {
    inset: 0 0 0 auto !important;
}

/*==================== data table ====================*/

.data-table {
    margin-top: 20px;
    background-color: #ffffff;
    min-height: calc(100vh - 250px);
    table {
        text-align: center;
        tr {
            * {
                padding: 10px;
                white-space: nowrap;
                @media (max-width: 767px) {
                    padding: 8px;
                }
            }
            th {
                color: $dark-grey;
                background-color: #efefef;
                font-size: 16px;
                font-weight: 500;
                border-right: 2px solid $secondary;
                @media (max-width: 767px) {
                    font-size: 14px;
                }
            }
            td {
                max-width: 350px;
                font-size: 16px;
                border-right: 2px solid #efefef;
                @media (max-width: 767px) {
                    font-size: 12px;
                }
            }
        }
    }
}

/*==================== view-details ====================*/
.view-details-wrapper {
    // width: 70%;
    margin: 0 auto;
    // background-color: #ffffff;
    border-radius: 10px;
    padding: 20px 50px;
    @media (max-width: 1480px) {
        width: 100%;
        padding: 20px 30px;
    }
    @media (max-width: 767px) {
        padding: 20px 0 0 0;
        background-color: $secondary;
    }

    .profile-header{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        flex-wrap: wrap;
        @media(max-width: 767px){
            justify-content: space-between;
        }
        .view-details-heading {
            font-size: 24px;
            display: flex;
            align-items: center;
            @media (max-width: 767px) {
                font-size: 20px;
                text-align: center;
                margin-top: 0;
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
            span {
                font-size: 14px;
                margin-left: 30px;
            }
        }
        .view-details-updated-by{
            font-size: 18px;
            font-weight: 400;
            margin-left: 30px;
            @media (max-width: 767px) {
                margin-left: 0;
                width: auto;
            }
            span{
                font-weight: 600;
                display: block;
            }
        }
        .profile-completition{
            margin-left: auto;
            display: flex;
            align-items: center;
            width: 200px;
            margin-right: 50px;
            @media(max-width: 767px){
                width: 100%;
                margin-top: 20px;
                margin-bottom: 20px;
                margin-left: 0;
                margin-right: 0;
            }
            .percent{
                font-size: 14px;
                width: 30px;
                text-align: center;
                color: #707070;
            }
            .percent-progress{
               margin-left: 5px;
               margin-right: 5px;
               width: calc(100% - 50px); 
               height: 9px;
               border: 1px solid #CCCCCC;
               border-radius: 6px;
               position: relative;
               overflow: hidden;
               .progress-bg{
                   background-color: #258CF2;
                   position: absolute;
                   height: 100%;
                   left: 0;
                   top:0;
               }
            }
        }
    }
    
    .view-details {
        @media (max-width: 767px) {
            background-color: #ffffff;
            border-radius: 10px;
            padding: 20px 20px;
        }
        @media (max-width: 359px) {
            padding: 15px 20px;
        }
        .label {
            font-size: 18px;
            color: $light-grey;
            padding: 10px 20px;
            border: 2px solid $secondary;
            border-radius: 4px;
            margin-bottom: 20px;
            box-shadow: 0px 0px 8px $secondary;
            @media (max-width: 991px) {
                font-size: 16px;
                border: none;
                box-shadow: none;
                padding: 0;
                margin-bottom: 5px;
            }
            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
        .content {
            font-size: 18px;
            padding: 10px 20px;
            border: 2px solid $secondary;
            border-radius: 4px;
            margin-bottom: 20px;
            margin-left: auto;
            box-shadow: 0px 0px 8px $secondary;
            @media (max-width: 991px) {
                font-size: 16px;
                padding: 10px 15px;
                margin-bottom: 12px;
            }
        }
    }
    .request-redirection{
        .redirection-item{
            margin-bottom: 10px;
            .redirection-link{
                display: block;
                padding: 10px 20px;
                color: #000000;
                border-radius: 10px;
                background-color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
}

// view page design

.profile-section-card {
    padding: 10px 20px;
    border-radius: 10px;
    // box-shadow: 0 0 23px -7px rgba(0, 0, 0, 0.18);
    background-color: #ffffff;
    margin-bottom: 30px;
    // height: 100%;

    .head-wrap {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #dedede;
        margin-bottom: 20px;
        padding-bottom: 10px;

        .heading {
            font-size: 20px;
            .badge-msg {
                font-size: 16px;
                font-weight: 500;
                @media (max-width: 767px) {
                    font-size: 14px;
                }
            }
        }

        .head-action {
            display: flex;

            .accet-reject {
                padding: 5px;
                margin-left: 20px;

                i {
                    font-size: 20px;
                }
            }
        }
    }

    .detail-field {
        .field-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .form-group {
                margin-bottom: 20px;
                width: calc(100% - 20px);

                .label-key {
                    font-size: 14px;
                    color: #858383;
                }

                .value {
                    font-size: 20px;
                    font-weight: bold;
                }
            }

            .admin-action-wrap {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 14px;
                margin-left: 10px;
                cursor: pointer;
                .admin-action{
                    width: 18px;
                }
                .label-key{
                    font-size: 12px;
                    cursor: pointer;
                }
            }
        }
    }
}

.commented-profile{
    margin-bottom: 30px;
    textarea{
        width: 100%;
        background-color: #FCF6BD;
        min-height: 200px;
        border:0;
        padding: 20px;
    }
}

// centered
.modal-fullscreen {
    margin: 0 auto;
}

.pdf-rendered {
    width: 100%;
    min-width: 280px;
    max-width: 800px;
    margin: 0 auto;
    max-height: 100%;
    height: 100%;
    // min-height: 500px;
}

.cursor-pointer {
    cursor: pointer;
}

.cusror-pointer {
    cursor: pointer;
}

// withdraw card
.card-three {
    // width: calc(50% - 47px);
    white-space: nowrap;
    margin-bottom: 50px;
    border-radius: 15px;
    box-shadow: 0 0 23px -7px rgba(0, 0, 0, 0.18);
    // @media (max-width: 1200px) {
    //     width: 100%;
    // }
    // @media (max-width: 767px) {
    //     margin-bottom: 25px;
    // }
    .card-info {
        background-color: #ffffff;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

        padding: 30px 45px 20px 30px;
        @media (max-width: 767px) {
            padding: 14px 20px 11px 20px;
        }
        .withdrawal {
            color: #acacac;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 20px;
            @media (max-width: 1368px) {
                font-size: 14px;
            }
            @media (max-width: 767px) {
                font-size: 10px;
                margin-bottom: 15px;
            }
        }
        .account-text {
            color: $dark-grey;
            font-size: 16px;
            margin-bottom: 15px;
            @media (max-width: 1368px) {
                font-size: 14px;
            }
            @media (max-width: 767px) {
                font-size: 10px;
                margin-bottom: 5px;
            }
        }
        .account-number {
            color: $dark-grey;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 20px;
            @media (max-width: 767px) {
                font-size: 14px;
                margin-bottom: 10px;
            }
        }
        .amount-info-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .heading {
                color: $dark-grey;
                font-size: 16px;
                font-weight: 400;
                margin-bottom: 15px;
                @media (max-width: 1368px) {
                    font-size: 14px;
                }
                @media (max-width: 767px) {
                    font-size: 10px;
                    margin-bottom: 5px;
                }
            }
            .value {
                color: $dark-grey;
                font-size: 18px;
                font-weight: 500;
                &.success-text {
                    color: #00a79d;
                }
                @media (max-width: 767px) {
                    font-size: 14px;
                }
                @media (max-width: 359px) {
                    font-size: 12px;
                }
            }
        }
    }
    .card-total {
        background-color: $primary;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;

        padding: 15px 45px 15px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #ffffff;
        @media (max-width: 767px) {
            padding: 10px 20px;
        }
        .total-text {
            font-size: 18px;
            font-weight: 400;
            @media (max-width: 767px) {
                font-size: 10px;
            }
        }
        .total-value {
            font-size: 18px;
            font-weight: 500;
            @media (max-width: 767px) {
                font-weight: 400;
            }
            @media (max-width: 359px) {
                font-size: 16px;
            }
        }
    }
}


// top filter
.top-filter-button{
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .btn-filter-list{
        border-radius: 10px;
        overflow: hidden;
        max-width: 800px;
        width: 100%;
        display: flex;
        transition: all ease-in-out 0.3s;
        .btn{
            border-radius: 0;
            margin-top: 0;
            font-size: 16px;
            font-weight: 400;
            transition: all ease-in-out 0.3s;
            &.active{
                background-color: $primary;
                color: #ffffff;
                font-weight: bold;
                transition: all ease-in-out 0.3s;
            }
        }
    }
    .search-wrapper{
        min-width: 300px;
        @media(max-width:767px){
            width: 100%;
        }
        .form-control{
            padding: 10px;
            border-radius: 10px;
        }
    }
}

.ascending_icons{
    height: 20px;
    width: 20px;
    padding: 0px !important;
}


.complete-profile-extra-action{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    flex-wrap: wrap;
    @media(max-width: 767px){
        justify-content: space-between;
    }
    .list-item{
        margin-left: 20px;
        position: relative;
        @media(max-width: 767px){
            width: calc(50% - 10px);
            margin-left: 0;
        }
        .form-control{
            margin-top: 10px;
            padding: 15px;
            min-width: 250px;
            border-radius: 10px;
            @media(max-width: 767px){
                padding: 10px 15px;
                min-width: auto;
            }
        }
        .dropdown-arrow{
            position: absolute;
            top: 50%;
            right: 20px;
            z-index: 1;
            transform: translateY(-50%);
            margin-top: 5px;
        }
    }
}

.scrollButton{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    .scroll_button{
        margin-left: 20px;
        cursor: pointer;
    }
}