.modal-header{
    border-bottom: none !important;
}
.modal-footer{
    display: flex;
    justify-content: space-between !important;
    border-top: none !important;
    .btn-logout{
        width: 20%;
        font-size: 16px;
        font-weight: 400;
        padding: 10px;
        @media (max-width: 767px) {
            font-size: 15px;
            width: 18%;
            padding: 8px;
        }
    }
}

.btn-color-danger{
    background-color: #dc3545 !important;
}
.btn-color-primary{
    background-color: #258cf2 !important;
}
.btn-color-grey{
    background-color: #707070 !important;
}