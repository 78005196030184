@import './theme.scss';

%full-centered {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px) {
      align-items: unset;
    }
  }

// 

%heading-one{
  font-size: 50px;
  margin-bottom: 0;
}

// invert page heading
%invert-page-heading{
  color: $primary;
}