$primary :#258cf2;
$secondary : #E5E5E5;
$light-grey:#A1A1A1;
$dark-grey: #707070;
$border-radius: 10px;
$white: #ffffff;



// headings
$heading-one: 25px;