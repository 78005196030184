.loader-wrapper{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    background-color: rgba(0,0,0,0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}