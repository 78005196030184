@import './../assets/Styles/theme.scss';
@import './../assets/Styles/placeholder.scss';

.main-wrapper{

    .content-outer-wrapper{
        width: calc(100% - 346px);
        padding: 0 25px;
        padding-bottom: 50px !important;
        margin-left: auto;
        @media (max-width: 1368px) {
            width: calc(100% - 300px);
        }
        @media (max-width: 767px) {
            width: 100%;
        }
        .content-wrapper{
            

        //     .page-heading{
        //         background-color: $primary;
        //         padding: 10px;
        //         margin-bottom: 20px;
        //         .heading-one{
        //             @extend %heading-one;
        //             color: $secondary;
        //         }
        //         &.invert-style{
        //             background-color: $secondary;
        //             .heading-one{
        //                 @extend %invert-page-heading;
        //             }
        //         }
        //     }
        //     .page-layout{
        //         background-color: $primary;
        //         padding: 10px;
        //     }
        }

    }
}